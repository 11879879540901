import { certificate } from "../../js/path";

export default {
  data() {
    return {
      images: [],
      form: {
        template_name: "",
        template: "",
        points: "",
        image_fillers: [
          {
            file: "",
            filler: "",
            key: 0
          },
        ],
        removed_fillers: [],
        text_fillers: [
          {
            filler: "",
            key: 0
          },
        ],
        pdf_url:"",
        image_url:""
      },
      file: "",
      edit: {
        filler_img_url: null,
      },
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "template_name",
          label: "Template Name",
        },
        {
          key: "points",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
        {
          key: "certificate_count",
          label: "Count Of Certificate"
        },

        {
          key: "Certificas",
          label: "genrate Certificas"
        },
      ],
      params: "",
      tableData: [],
      activeTab: "all",
      key: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      pageNo: null,
    };
  },
  methods: {
    // readFile(event, index) {
    //     const file = event.target.files[0];
    //     if (file) {
    //       const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
    //       this.form.image_fillers[index].file = file;
    //       this.form.image_fillers[index].filler = fileNameWithoutExtension;
    //     }
    // },
    // readFile(event, index) {
    //   const file = event.target.files[0];
    //   if (file) {
    //       const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
    //       this.form.image_fillers[index].file = file.name;
    //       this.form.image_fillers[index].filler = fileNameWithoutExtension;
    //   }
    // },
    // addImage() {
    //   let newKey = this.form.image_fillers.length;
    //   this.form.image_fillers.push({
    //     key: newKey,
    //     file: null,
    //     filler: null
    //   });
    // },
    // removeImage(index) {
    //   if (index === 0) return;
    //   this.form.image_fillers.splice(index, 1);
    // },
    onFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
        this.form.image_fillers[index].file = file;
        this.form.image_fillers[index].fileName = file.name;
        this.form.image_fillers[index].filler = fileNameWithoutExtension;
        this.form.image_fillers[index].filler_img_url = URL.createObjectURL(file);
      }
    },
    addImage() {
      this.form.image_fillers.push({ file: null, filler_img_url: '', filler: '' });
    },
    removeImage(index) {
      this.form.image_fillers.splice(index, 1);
    },
    addTextFiller() {
      let newKey = this.form.text_fillers.length;
      this.form.text_fillers.push({
        key: newKey,
        filler: null
      });
    },
    removeTextFiller(key) {
      if (key == 0) return;
      this.form.text_fillers.splice(key, 1);
    },
    searchFor() {
      if (this.filter.length > 1) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData('search');
        }
      } else if (this.filter.length == 0) this.fetchData('search');
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = certificate.certificateUrl;
        if (pagination == "search") {
          url = certificate.certificateUrl + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async editCertificate(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = certificate.editCertificates + "/" + id;
        const data = await this.getRequest(url);
        console.log(data);
        if (data.status) {
          const responseData = data.response;
          console.log(responseData);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchCertificate(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = certificate.certificateUrl + "/" + id;
        const data = await this.getRequest(url);
        console.log(data);
        if (data.status) {
          const responseData = data.response;
          this.form.template_name = responseData.template_name;
          this.form.template = responseData.template;
          this.form.points = responseData.points;

          if (responseData.image_fillers != "" && responseData.image_fillers != null) {
            this.form.image_fillers = responseData.image_fillers;
          }

          if (responseData.pdf_url != "" && responseData.pdf_url != null) {
            this.form.pdf_url = responseData.pdf_url;
          }

          if (responseData.image_url != "" && responseData.image_url != null) {
            this.form.image_url = responseData.image_url;
          }

          this.edit.filler_img_url = responseData.image_fillers.file;
          this.file = responseData.image_fillers.file;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteCertificate(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = certificate.certificateUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        const url = certificate.certificateUrl;
        let dataAppend = new FormData();
        for (var key in this.form) {
          if (key != "image_fillers") {
            dataAppend.append(key, this.form[key]);
          }
        }

        this.form.image_fillers.forEach((imageFiller, index) => {
          dataAppend.append(`filler[${index}][file]`, imageFiller.file);
          dataAppend.append(`filler[${index}][filler]`, imageFiller.filler);
        });
        dataAppend.append("image_fillers", JSON.stringify(this.form.image_fillers.map(({ fileName, filler }) => ({ file:fileName, filler }))));
        dataAppend.append("text_fillers", JSON.stringify(this.form.text_fillers));
        
      
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/certificate");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateData(id) {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        const url = certificate.certificateUrl + "/" + id;
        let dataAppend = new FormData();
        for (var key in this.form) {
          if (key != "image_fillers") {
            dataAppend.append(key, this.form[key]);
          }
      }
      
      // Create a FormData object for file uploads
      // let dataAppend = new FormData();
      
      this.form.image_fillers.forEach((imageFiller, index) => {
        dataAppend.append(`image_fillers[${index}][file]`, imageFiller.file);
        dataAppend.append(`image_fillers[${index}][filler]`, imageFiller.filler);
      });
        // dataAppend.append("text_fillers", JSON.stringify(this.form.text_fillers));
        dataAppend.append("_method", "put");
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/certificate");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async regenerateCertificate(id) {
      const url = certificate.regenerateCertificate + '/' + id;
      const data = await this.getRequest(url);
      if (data.status) {
         this.$store.commit('toast/updateStatus', {
            status: true,
            icon: 'success',
            title: data.message
         });
      }
   },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.fetchData(value);
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-certificate" ||
      this.$route.name == "edit-certificate"
    ) {
      if (this.$route.name == "edit-certificate") {
        this.fetchCertificate(this.$route.params.id);
        this.editCertificate(this.$route.params.id);
      }
    } else {
      this.fetchData("all");
    }
  },
};
